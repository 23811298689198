.container {
  width: 100%;
}

.contentTitle {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  padding: 20px 20px 0px 10px;
}

.contentBody {
  padding-left: 10px;
  padding-right: 20px;
}

.content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  border-radius: 5px;
}

.textSection {
  flex: 3;
  overflow: hidden;
}

.textSection {
  cursor: pointer;
}

.type {
  font-size: 14px;
}

.title {
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subTitle {
  font-size: 14px;
  color: #555;
}

.buttonSection {
  display: flex;
  align-items: center;
}

.searchBtn {
  background: none; /* 기본 배경 제거 */
  border: none; /* 기본 테두리 제거 */
  padding: 0; /* 기본 패딩 제거 */
  margin: 0 5px; /* 버튼들 사이에 간격 추가 */
  cursor: pointer; /* 커서 스타일 */
}

.contentBottom {
  display: flex;
}

.moreButton {
  width: 100%;
  height: 43px;
  font-size: 14px;
  font-weight: 800;
  color: #c9c9c9;
  background: none; /* 기본 배경 제거 */
  border: none; /* 기본 테두리 제거 */
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  padding: 0; /* 기본 패딩 제거 */
  cursor: pointer; /* 커서 스타일 */
}
