.container {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
}

.searchTerm {
  display: flex;
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  opacity: 0;
  transform: translateY(100%);
  animation: slideIn 1s forwards;
  padding-left: 10px;
  text-wrap: pretty;
  gap: 10px;
  cursor: pointer;
}

/* Keyframes for slide-in animation */
@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
