.detailWindow {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  transition: height 0.3s ease;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.handleBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 30px;
  height: 30px;
}
.dragHandle {
  width: 50px;
  height: 5px;
  background-color: gray;
  border-radius: 3px;
  cursor: grab;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 10px 10px 0px 10px;
  flex-grow: 1;
  overflow-y: auto;
}

.contentTitle {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  padding-left: 10px;
}

.itemList {
  list-style-type: none;
  padding: 0;
}

.contentBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
}
