@font-face {
  font-family: "Pretendard";
  src: url("/public/fonts/Pretendard-Regular.woff2") format("woff2"),
    url("/public/fonts/Pretendard-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url("/public/fonts/Pretendard-Bold.woff2") format("woff2"),
    url("/public/fonts/Pretendard-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

body {
  font-family: "Pretendard", sans-serif;
  margin: 0;
  background-color: #efefef;
}
