.container {
  margin: 9px 7px 0 7px;
  padding: 0;
  border: 0;
  position: relative;
}

.inputBar {
  box-sizing: border-box;
  width: 100%;
  padding: 10px 80px 10px 15px;
  height: 48px;
  font-size: 16px;
  border-radius: 8px;
  color: #f5f5f5;
  /* border-radius: 10px 10px 0 0; */
  border: none;
}

.inputBar::placeholder {
  color: #f5f5f5;
  font-weight: normal;
  opacity: 1;
}

.inputBar:focus {
  outline: none;
}

.searchBtn {
  padding: 0;
  position: absolute;
  top: 5px;
  right: 16px;
  width: 20px;
  height: 20px;
  border: none;
  color: #f5f5f5;
  background-color: transparent;
  cursor: pointer;
}

.searchIcon {
  width: 20px;
  height: 20px;
}

.searchIcon:hover {
  fill: white;
}

.resetBtn {
  padding: 0;
  position: absolute;
  top: 5px;
  right: 52px;
  width: 20px;
  height: 20px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 8px;
  color: white;
}

.resetIcon {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

.resetIcon:hover {
  fill: white;
}

.suggestionsList {
  border: 1px solid #00a0fa;
  padding: 0;
  margin: 0;
  list-style-type: none;
  width: 100%;
  position: absolute;
  top: 48px; /* inputBar의 높이와 동일하게 설정 */
  left: 0;
  background-color: #ffffff;
  z-index: 9999;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.suggestionItem {
  padding: 10px;
  cursor: pointer;
  background-color: #ffffff;
  /* border-bottom: 1px solid #ccc; */
}

.suggestionItem:hover {
  background-color: #e0e0e0;
}

.highlighted {
  background-color: #e0e0e0;
}
