.container {
  width: 100%;
  box-sizing: border-box;
}

.categoryButtons {
  display: flex;
  margin: 8px;
  gap: 10px 8px;
  white-space: nowrap;
  overflow-x: hidden;
  cursor: grab;
  user-select: none;
}

.button {
  width: auto;
  height: auto;
  padding: 8px 8px;
  margin: 0;
  font-size: 14px;
  color: black;
  background-color: transparent;
  border: 1px solid black;
  border-radius: 10px;
  cursor: pointer;
  flex-shrink: 0;
}

/* .active {
  color: #ffcc00;
  border-color: #ffcc00;
} */

.optionsModal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 65%;
  background-color: #f5f5f5;
  box-shadow: 0px -2px 3px rgba(0, 0, 0, 0.1);
  padding: 20px 0px 50px 0px;
  box-sizing: border-box;
  overflow-y: auto;
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

.options {
  display: flex;
  flex-direction: column;

  padding-bottom: 30px; /* Ensure enough space for the apply button */
}

.categorySection {
  display: flex;
  flex-direction: column;
}

.categoryTitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.categoryOptions {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.rangeSliderContainer {
  display: flex;
  width: 95%;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.rangeLabels {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #333;
}

.optionButton {
  padding: 8px 15px;
  font-size: 14px;
  background-color: white;
  border: 1px solid #efefef;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

/* .optionActive {
  color: #ffcc00;
  border-color: #ffcc00;
} */

.buttonGroup {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  height: 80px;
  gap: 10px;
  z-index: 1001;
  align-items: center;
  background-color: #f5f5f5;
  position: fixed;
  bottom: 0px;
}

.resetButtonSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.resetButton {
  width: 30px;
  height: 30px;
  transform: rotate(75deg);
}

.applyButton {
  width: 264px;
  height: 40px;
  color: white;
  border: none;
  font-weight: 600;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}
