.carouselContainer {
  width: 100%;
  overflow: hidden;
  position: relative;
  user-select: none;
}

.carouselWrapper {
  display: flex;
  transition: transform 0.3s ease-in-out;
  margin-top: 10px;
}

.carouselSlide {
  flex: 0 0 100%;
  background-color: #f5f5f5;
  padding-top: 10px;
}

.subItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  font-size: 12px;
  padding-left: 10px;
  gap: 4px;
}

.subItemTitle {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: red;
  margin: 0;
  gap: 4px;
}

.pageIndicators {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  background-color: #f5f5f5;
}

.pageIndicator {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #79747e;
  margin: 0 3px;
  transition: background-color 0.3s ease;
}

.activePage {
  background-color: #00a0fa;
}
