.categorySection {
  display: flex;
  flex-direction: column;
}

.categoryTitle {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  padding-left: 10px;
}

.categoryOptions {
  display: flex;
  flex-wrap: wrap;
}

.rangeSliderContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
  align-items: center;
}

.rangeLabels {
  display: flex;
  justify-content: space-evenly;
  font-size: 14px;
  color: #49454f;
}

.buttonContainer {
  display: flex;
  flex-wrap: wrap;
  text-wrap: nowrap;
  justify-content: center;
  width: 100%;
  gap: 20px 15px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.optionButton {
  width: 92px;
  height: 40px;
  padding: 6px 16px 6px 16px;
  font-size: 14px;
  color: #49454f;
  background-color: transparent;
  border: 1px solid #79747e;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}
