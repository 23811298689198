.detailWindow {
  transition: height 0.3s ease;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.fullScreen {
  height: 100vh; /* 전체 화면 */
}

.dragHandle {
  width: 50px;
  height: 5px;
  background-color: gray;
  margin: 10px auto;
  border-radius: 3px;
  cursor: grab;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
}

.contentTitle {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  padding-left: 10px;
}

.itemList {
  list-style-type: none;
  padding: 0;
}

.contentBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
}
