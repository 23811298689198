.categoryTitle {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  padding-left: 10px;
}

.categoryOptions {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.optionButton {
  width: max-content;
  text-align: center;
  text-wrap: nowrap;
  padding: 10px 15px;
  font-size: 14px;
  color: #49454f;
  background-color: transparent;
  border: 1px solid #79747e;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}
