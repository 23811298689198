.container {
  display: flex;
  width: 100px;
  height: 46px;
  position: fixed;
  bottom: 30px;
  right: 20px;
  z-index: 1000;
}

.button {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  border: 1px solid #ffcc00;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  font-weight: 700;
  font-size: 16px;
  text-wrap: nowrap;
}

/* .button:hover { */
/* background-color: black; */
/* } */
