/* Map.module.css */
.mapContainer {
  width: 100%;
  height: 91%;
}

.bubbleMarker {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.refreshBtn {
  width: 173px;
  height: 34px;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #d9d9d9;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.refreshBtn:hover {
  background-color: #c9c9c9;
}

.bubbleContent {
  position: relative;
  display: inline-flex;
  cursor: pointer;
}

.flagPole {
  width: 2px;
  height: 65px; /* 막대 길이 */
  background-color: #333;
}

.flagContent {
  display: flex;
  background-color: white;
  border: 2px solid;
  font-size: 12px;
  width: 60px;
  transition: all 0.3s ease;
  position: absolute;
  top: 0;
  /* left: 0px; flagPole의 너비만큼 */
}

.verticalText {
  writing-mode: vertical-rl;
  text-orientation: upright;
  font-size: 10px;
  font-weight: bold;
  color: white;
  white-space: nowrap; /* 텍스트가 한 줄로 유지되도록 */
  overflow: hidden; /* 넘치는 텍스트를 숨김 */
  text-overflow: ellipsis; /* 말줄임표를 적용 */
  width: 15px;
  height: auto;
  max-height: 48px;
  text-align: center;
}

.mainContent {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  font-size: 10px;
  width: 48px;
}

.bubbleHeader {
  display: flex;
  flex-direction: column;
  font-size: 11px;
  font-weight: bold;
  gap: 2px;
}

.bubbleBody {
  display: none;
  padding: 2px;
}

.bubbleBody > div {
  margin-bottom: 3px;
}

/* Expanded state */
.bubbleContent.expanded .flagContent {
  flex-direction: column;
  max-width: none;
  width: max-content;
  background-color: white;
  color: black;
  border: 2px solid #ff9500;
  z-index: 10;
}

.bubbleContent.expanded .bubbleBody {
  display: block;
}

.bubbleContent.expanded .bubbleHeader {
  display: none;
}

.bubbleContent.expanded .flagPole {
  height: 110px;
}

.bubbleContent.expanded .mainContent {
  order: 2;
  width: max-content;
}

.bubbleContent.expanded .verticalText {
  order: 1;
  writing-mode: horizontal-tb;
  width: 100%;
  padding: 5px 0;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 30px;
  text-indent: 30px;
}

/* Contract states */
.bubbleContent.past-contract .flagContent {
  border-color: #34c759;
}

.bubbleContent.now-contract .flagContent {
  border-color: #5856d6;
}

.bubbleContent.future-contract .flagContent {
  border-color: #ff9500;
}

.bubbleContent.past-contract .flagPole {
  background-color: #34c759;
}

.bubbleContent.now-contract .flagPole {
  background-color: #5856d6;
}

.bubbleContent.future-contract .flagPole {
  background-color: #ff9500;
}

.bubbleContent.expanded.past-contract .flagContent {
  border-color: #34c759;
}

.bubbleContent.expanded.now-contract .flagContent {
  border-color: #5856d6;
}

.bubbleContent.expanded.future-contract .flagContent {
  border-color: #ff9500;
}

/* 수직 텍스트의 배경색 조정 */
.bubbleContent.past-contract .verticalText {
  background-color: rgba(52, 199, 89, 0.8);
}

.bubbleContent.now-contract .verticalText {
  background-color: rgba(88, 86, 214, 0.8);
}

.bubbleContent.future-contract .verticalText {
  background-color: rgba(255, 149, 0, 0.8);
}

.bubbleContent.zoom .flagPole {
  background-color: #007aff;
}

.bubbleContent.zoom .flagContent {
  border-color: #007aff;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bubbleContent.zoom .verticalText {
  background-color: #007aff;
}
