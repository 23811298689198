.container {
  display: flex;
  width: 36px;
  height: 36px;
  position: fixed;
  bottom: 30px;
  right: 20px;
  z-index: 1000;
}

.button {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
  background-color: gray;
}

.button:hover {
  background-color: #00a0fa;
}
