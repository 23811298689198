.categorySection {
  display: flex;
  flex-direction: column;
}

.categoryTitle {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  padding-left: 10px;
}

.categoryOptions {
  display: flex;
  flex-wrap: wrap;
}

.buttonContainer {
  display: flex;
  width: 100%;
  gap: 24px;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.optionButton {
  width: 90px;
  height: 40px;
  padding: 6px 16px 6px 16px;
  font-size: 14px;
  color: #49454f;
  background-color: transparent;
  border: 1px solid #79747e;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.rangeSliderContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  align-items: center;
}

.rangeLabels {
  display: flex;
  justify-content: space-evenly;
  font-size: 14px;
  color: #49454f;
}

.inputSection {
  display: flex;
  width: 100%;
  height: 46px;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
