.selectContainer {
  position: relative;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.selectBox {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.selectButton {
  width: 100%;
  padding: 15px 15px 15px 10px;
  font-size: 16px;
  cursor: pointer;
  text-align: left;
  background-color: white;
  border: none;
}

.optionsList {
  position: absolute;
  width: 100%;
  top: 97%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1;
}

.option {
  padding: 10px;
  cursor: pointer;
}

.option:hover {
  background-color: #f0f0f0;
}
