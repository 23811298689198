.categoryTitle {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  padding-left: 10px;
}

.categoryOptions {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.buttonContainer {
  display: flex;
  height: 200px;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.buttonContainer::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera: 스크롤바 숨김 */
}

.buttonSection {
  display: flex;
  height: max-content;
  padding: 20px;
}

.optionButton {
  width: max-content;
  max-height: 40px;
  text-align: center;
  text-wrap: nowrap;
  padding: 10px 15px;
  font-size: 14px;
  color: #49454f;
  background-color: transparent;
  border: 1px solid #79747e;
  border-radius: 10px;
  transition: background-color 0.3s, border-color 0.3s;
}

.resetButton {
  width: 20px;
  border: 1px solid #efefef;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}
