.carouselContainer {
  width: 100%;
  overflow: hidden;
  position: relative;
  user-select: none;
}

.carouselWrapper {
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.carouselSlide {
  flex: 0 0 50%;
  background-color: #f5f5f5;
  margin-right: 10px;
}

.subItem {
  width: 100%;
  height: 100%;
  font-size: 12px;
  padding: 6px;
}

.subItemTitle {
  font-size: 14px;
  font-weight: bold;
  color: red;
  margin: 0;
}

.pageIndicators {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  background-color: #f5f5f5;
}

.pageIndicator {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #79747e;
  margin: 0 3px;
  transition: background-color 0.3s ease;
}

.activePage {
  background-color: #ffcc00;
}
